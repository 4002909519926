'use client';
import React, { useState } from "react";
import styled from "./style.module.scss";
import image1 from "../../.././src/asset/carusel/1.gif";
import image2 from "../../.././src/asset/carusel/2.gif";
import image3 from "../../.././src/asset/carusel/3.gif";
import image4 from "../../.././src/asset/carusel/4.gif";
import image5 from "../../.././src/asset/carusel/5.gif";
import Slider from "react-slick";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import introVideo from '../../asset/video.mp4'
const CaruselHeader = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,  
  };
  return (
    <Slider className={styled.carusel_body_header} {...settings}>
    <div>
    <Video autoplay loop>
        <source src={introVideo} type='video/mp4' />
    </Video>
    </div>
    <div>
      <img src={image1}/>
    </div>
    <div>
      <img src={image2}/>
    </div>
    <div>
      <img src={image3}/>
    </div>
    <div>
      <img src={image4}/>
    </div>
    <div>
      <img src={image5}/>
    </div>
    
  </Slider>
    
  );
};

export default CaruselHeader;
