const { default: axiosInstance } = require(".");

// const BASE_URL = process.env.BASE_API_URL
export const registerUser = async (payload) => {
   try {
      const response = await axiosInstance.post(`https://new-era-server.vercel.app/api/users/register`, payload)
      return response.data
   } catch (error) {
      return error.response.data
   }
}

// LOGIN USER

export const loginUser = async (payload) => {
   try {
      const response = await axiosInstance.post(`https://new-era-server.vercel.app/api/users/login`, payload)
      return response.data
   } catch (error) {
      return error.response.data
   }
}


// Get User Information

export const getUserInfo = async () => {
   try {
      const response = await axiosInstance.post('https://new-era-server.vercel.app/api/users/get-user-info')
      return response.data
   } catch (error) {
      return error.response.data
   }
}