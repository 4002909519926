import React, { useRef } from 'react'
import styled from './style.module.scss'
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import HomeHeader from '../.././components/HomeHeader';
import HomeFooter from '../../components/HomeFooter';
import { useFormik   } from "formik";
import * as Yup from "yup";

import { useTranslation } from 'react-i18next';
import ReactWhatsapp from 'react-whatsapp';
import { IoLogoWhatsapp } from 'react-icons/io';

const Contact = () => {
    const form = useRef();
    const { t } = useTranslation();
    const formik = useFormik({
      initialValues: {
        userName:'',
        email:'',
        number:'',
        education:'',
 
      },
      validationSchema: Yup.object({
        userName: Yup.string()
          .min(5, "Bu bölmə 5 simvoldan az ola bilməz")
          .required("*Bu xananı doldurun!"),
        email:Yup.string().email('keçərsiz email adres'),
        number:Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(9, 'Minimum rəqəm sayı 9 olmalıdır')
      .max(9, 'Maximum rəqəm sayı 9 olmalıdır')
      .required('*Bu xananı doldurun!'),
        education:Yup.string().min(20,'Bu bölmə 20 simvoldan az ola bilməz')
        .required('*Bu xananı doldurun!'),         
      }),
      onSubmit: async (values) => {
        
        try {
           emailjs.sendForm(
            "service_jhish0e", // Replace with your EmailJS service ID
            "template_t2hgqtw", // Replace with your EmailJS template ID
            form.current,
            "Xs6ISsqqHkXCuNQq7" // Replace with your EmailJS user ID
          );
      
          // Successful submission
          toast(t("alert.success"), {
            duration: 4000,
            position: 'top-center',
            icon: '✅',
          });
          formik.resetForm();
        } catch (error) {
          // Submission error
          toast(t("alert.faild"), {
            duration: 4000,
            position: 'top-center',
            icon: '❌',
  
          });
        }
      },
    });
  

  return (
    <div>
    <HomeHeader/>
    <ReactWhatsapp
        className={styled.whatsapp}
        number="994-55-561-07-00"
        message="Salam,hər vaxtınız xeyir."
        width={"100px"}
        height={"100px"}
      >
        <IoLogoWhatsapp />
      </ReactWhatsapp>
         <div className={styled.contact_section}>
          <div className={styled.container}>
            <div className={styled.contact_body}>
            <div className={styled.contact_body_left}>
              <h2>{t("contact.h1")}</h2>
            <form ref={form} onSubmit={formik.handleSubmit}>        
            <div>
            <div className={styled.about_userBody}>

              <div className={styled.inp_name_body}>
                <p 
                
                className={styled.exam_name}>{t("contact.input.inp1.header")}</p>
                <div 
                
                className={styled.inp_body}>
                  
                  <input
                    id="userName"
                    name="userName"
                    type="text"
                    placeholder={t("contact.input.inp1.placeholder")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                  />
                </div>
                {formik.touched.userName && formik.errors.userName ? (
                  <div className={styled.require}>
                    {formik.errors.userName}
                  </div>
                ) : null}
              </div>
         
           
            <div className={styled.inp_name_body}>
                <p 
                 
                className={styled.exam_name}>{t("contact.input.inp2.header")}</p>
                <div 
              
                className={styled.inp_body}>
                  <input
                  
                    id="email"
                    name="email"
                    type="email"
                    placeholder="E-mail hesabınızı daxil edin"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className={styled.require}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            <div className={styled.inp_name_body}>
                <p 
                 
                className={styled.exam_name}>{t("contact.input.inp3.header")}</p>
                <div 
                
                className={styled.inp_body}>
                 (+994)
                  <input
                  
                    id="number"
                    name="number"
                    type="number"
                    placeholder={t("contact.input.inp3.placeholder")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                  />
                </div>
                {formik.touched.number && formik.errors.number ? (
                  <div className={styled.require}>
                    {formik.errors.number}
                  </div>
                ) : null}
              </div>
            
            
            <div className={styled.textArea_name_body}>
                <p 
                 
                className={styled.exam_name}>{t("contact.input.inp4.header")}</p>
                <div 
              
                className={styled.inp_body}>
                  <textarea
                  
                    id="education"
                    name="education"
                    type="text"
                    placeholder={t("contact.input.inp4.placeholder")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.education}
                  />
                </div>
                {formik.touched.education && formik.errors.education ? (
                  <div className={styled.require}>
                    {formik.errors.education}
                  </div>
                ) : null}
              </div>
            
              
              
            </div>

            </div>
            <div className={styled.sumbitBtnBody}>
            <button className={styled.formSumbit} type="submit">{t("contact.input.btn")}</button>
            </div>
            </form>
            </div>
              <div className={styled.contact_body_right}>
              <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_u25cckyh.json"  background="transparent"  speed="1" loop  autoplay></lottie-player>
              </div>
            </div>
          </div>
        </div>
        <Toaster/>
        <HomeFooter/>
    </div>
  )
}

export default Contact