import React from "react";
import styled from "./style.module.scss";
import { AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { Toaster } from "react-hot-toast";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import footerLogo from "../../asset/logo/newEraLogo.png";
import { useTranslation } from "react-i18next";
const HomeFooter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <footer className={styled.footer__home}>
        {/* <ScrollToTop onClick={whatsappURL}  /> */}
        <div className={styled.footer__container}>
          <div className={styled.footer_content}>
            <div className={styled.footer_left_body}>
              <div className={styled.footer_left}>
                <img src={footerLogo} alt="Logo" />
                <h1>
                  {/* <span style={{ color: "#202124" }}>ITAC</span> */}
                </h1>
              </div>
              <div className={styled.footer__bottom}>
                <div>
                  <p>{t("footer.copyright")}</p>
                </div>
              </div>
            </div>
            <div className={styled.middle_body}>
              <div className={styled.footer_middle}>
                <h1>{t("footer.pages.h1")}</h1>
                <p>
                  <Link
                    onClick={() => navigate("/")}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    {t("footer.pages.mainPage")}
                  </Link>
                </p>
                <p>
                  <Link
                    onClick={() => navigate("/about")}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    {t("footer.pages.about")}
                  </Link>
                </p>
                <p>
                  <Link
                    onClick={() => navigate("/contact")}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    {t("footer.pages.contact")}
                  </Link>
                </p>
              </div>
              <h3> {t("footer.pages.watchUs")}</h3>
              <div className={styled.effect}>
                <div className={styled.buttons}>
                  <a
                    target={"_blank"}
                    href="https://www.instagram.com/newera_production_and_event/"
                    className={styled.insta}
                    title="İnstagram-da bizə qoşulun"
                  >
                    <i>
                      <AiOutlineInstagram />
                    </i>
                  </a>
                  <a
                    target={"_blank"}
                    href="https://www.youtube.com/@NewEraP300"
                    className={styled.in}
                    title="Youtube-da bizə qoşulun"
                  >
                    <i>
                      <FaYoutube />
                    </i>
                  </a>
                  <a
                    target={"_blank"}
                    href="https://t.me/+7jYg7tMqsnlhMmYy"
                    className={styled.github}
                    title="Telegramda-da bizə qoşulun"
                  >
                    <i>
                      <BsTelegram />
                    </i>
                  </a>
                </div>
              </div>
            </div>

            <div className={styled.footer_right}>
              <h1>{t("footer.contact.h1")}</h1>
              <div className={styled.social_media}>
                <p>
                  <GoLocation />
                  {t("footer.contact.location1")}
                </p>
                <p>
                  <GoLocation />
                  {t("footer.contact.location2")}
                </p>
                <p>
                  <GoLocation />
                  {t("footer.contact.location3")}
                </p>
                <p>
                  <AiOutlineMail /> info@newera.az
                </p>
                <p>
                  <BsFillTelephoneFill /> +994 (55) 561-07-00
                </p>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </footer>
    </>
  );
};

export default HomeFooter;
