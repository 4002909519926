import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng"),
  default: "az",
  resources: {
    az: {
      translation: {
        navbarItem: {
          about: "Haqqımızda",
          contact: "Əlaqə",
          mainPage: "Ana səhifə",
        },
        homeHeader: {
          h1: "Xəyalınızdakı tədbiri bizimlə gerçəkləşdirin!",
          p: "Kurslar & Kurs axtar. İstədiyin kursa bizimlə başla.",
        },
        inputPlaceholder: {
          name: "Tədbir axtar...",
          location: "Ölkə, Şəhər",
          company: "Şirkət",
          find: "Axtar",
        },
        apply: {
          h1: "Tədbir Üçün Müraciət et",
          text: "Axtardığınız tədbiri tapmadınızsa üzülməyin. Bizə müaraciət edin və istədiyiniz tədbiri biz sizin üçün reallaşdıraq.",
          btn: "Sifariş et",
          btn2: "Bütün tədbirlərə bax",
          btn3: "Daha çox",
        },
        jobsHader: {
          h1: "Bizdən icariyə götürə biləcəyiniz dekorlar",
          p: "İndi xəyalınızdakı tədbiri keçirmək üçün bizə müraciət edin.",
        },
        selection: {
          selec1: "Tarixə görə sırala",
          optionDate1: "ən yeni",
          optionDate2: "ən köhnə",
          selec2: "Qiymətə görə sırala",
          optionSalary1: "çoxdan az'a",
          optionSalary2: "azdan çox'a",
        },
        joinUs: {
          h1: "Telegramda bizə qoşul",
          text: "Həyata keçrilən tədbirlərdən xəbərdar olmaq istəyirsinizsə, Telegram-da bizə qoşulun və ən son tədbirlərdən ilk siz xəbərdar olun.",
          btn: "Telegramda bizə qoşul",
        },
        carusel: {
          h1: "Kurslarımız",
          p: "Kursu müvəffəqiyyətlə Bitirdikdən Sonra Xaricdə İş İmkanları",
        },
        about: {
          header: {
            h1: "Salam, NewEra'ya Xoş Gəlmisiniz!.",
            p: "New Era production komandası toy, nişan, ad günləri, konsert səhnələri və hər növ tədbirlərin təşkilini peşəkar formada həyata keçirir.",
          },
          about2: {
            h1: "Haqqımızda",
            text: "NewEra-toy, nişan, ad günü və konsert kimi unikal və özəl günlərinizi unudulmaz etmək üçün tərtibat komandası kimi bizim New Era Production komandamız, tədbirinizi peşəkarlıqla təşkil edir. Bizim əsas məqsədimiz, hər tədbiri sizin xüsusi istəkləriniz və tələblərinizə tam uyğun şəkildə təşkil etməkdir.Tədbirinizin möhtəşəm bir görünüşlü olunması üçün peşəkarlığımızı nümayiş etdiririk. Dekorasiya, işıqlandırma, səhnə dizaynı və şou proqramı tədbirinizin uğurunun əsas hissələrindən yalnız bir neçəsidir. Bizim təcrübəli komandamız, tədbirinizin hər bir detalını yaxından izləyir və əmin olur ki, hər şey təqviminizə və gözləntilərinizə cavab versin.Sizə təklifimizdir ki, növbəti tədbirinizi bizə etibar edin və biz, bu unikal və xüsusi günlərinizi zövqünüzə uyğun şəkildə təşkil edək. Unudulmaz bir tədbir üçün bizi seçin! ",
          },
          vacancy: {
            h1: "Tədbirlərimiz",
            text1: "-Cinsiyyət partisi ",
            text2: "-Ad qoyma mərasimi",
            text3: "-Ad günü",
            text4: "-Məzuniyyət partisi",
            text5: "-Nişan mərasimi",
            text6: "-Xına mərasimi",
            text7: "-Toy mərasimi",
            text8: "-Yubiley",
            text9: "-Korparativ tədbirlər",
          },
          obligation: {
            h1: "Öhdəliklər",
            text: "Qeyd edilən kurslar üzrə işçilər Polşa Respublikasında ödənişsiz qarşılanma, yerləşdirilmə, yaşayış yeri, işçi formaları, tibbi xidmətlər və bank xidmətləri ilə təmin olunur. İşə qəbul olunduğu təqdirdə sənədləşmə prossesi zamanı sənədlərin rüsumları və yol xərcləri namizəd tərəfindən ödənilir. İşə qəbul üçün müraciət edən namizədlərdən seçdiyi vakansiya üzrə iş təcrübəsinin və xarici dil biliklərinin(Rus və yaxud İngilis dili) olması tələb olunur.",
          },
          endText: {
            h1: "Gəlin növbəti dünya səviyyəli tədbirinizi biz hazırlayaq",
          },
        },
        contact: {
          h1: "Suallarınız var? Bizimlə Bölüş",
          input: {
            inp1: {
              header: "Ad Soyad",
              placeholder: "ad,soyad daxil edin",
            },
            inp2: {
              header: "E-mail",
              placeholder: "E-mail hesabınızı daxil edin",
            },
            inp3: {
              header: "Mobil nömrə",
              placeholder: "xx xxx xx xx",
            },
            inp4: {
              header: "Mesaj",
              placeholder: "Mesajınızı daxil edin...",
            },
            btn: "Göndər",
            validation: {
              empty: "*Bu xananı doldurun!",
            },
          },
        },
        alert: {
          success: "Mesajınız uğurla göndərildi!",
          faild: "Xəta baş verdi",
        },
        alert2: {
          success: "Müraciətiniz uğurla göndərildi!",
          faild: "Xəta baş verdi",
        },
        footer: {
          copyright: "Copyright © 2023 NewEra. Bütün hüquqlar qorunur.",
          pages: {
            h1: "Səhifələr",
            about: "Haqqımızda",
            contact: "Əlaqə",
            mainPage: "Ana səhifə",
            watchUs: "Bizi izlə",
          },
          contact: {
            h1: "Əlaqə məlumatı",
            location1: "Baş ofis:Lermontov küçəsi 109",
            location2:
              "Azadlıq filialı ünvanı:Bakı Şəhəri, Azadlıq prospekti 88",
            location3:
              "Tədris və istehsal zavodu ünvanı:Ələsgərov Qayıbov 1222",
          },
        },
        detail: {
          h1: "Elan Haqqında",
          buttons: {
            apply: "Elana müraciət edin",
            creatCv: "Müraciət et",
            uploadCv: "Hazır Cv Yüklə",
            close: "Bağla",
          },
          createCvInp: {
            title: "Elana müraciət edin",
            jobName: "Müraciət etdiyiniz elan",
            companyName: "Müraciət etdiyiniz şirkət",
            Location: "Ölkə",
            inp1: {
              label: "Ad, Soyad",
              placeholder: "ad, soyad daxil edin",
            },
            inp2: {
              label: "Yaş",
              placeholder: "Yaşı daxil",
            },
            inp3: {
              label: "E-mail",
              placeholder: "E-mail daxil edin",
            },
            inp4: {
              label: "Mobil nömrə",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Dil biliyi",
              placeholder: "Dil biliyini ətraflı şəkildə daxil edin",
            },
            inp6: {
              label: "Ünvan",
              placeholder: "Ünvanı daxil edin",
            },
            inp7: {
              label: "Keçriləcək tədbir",
              placeholder: "Keçriləcək tədbiri ətraflı şəkildə daxil edin",
            },
            inp8: {
              label: "İş təcrübəniz",
              placeholder: "İş təcrübənizi ətraflı şəkild daxil edin",
            },
            btn: "Göndər",
            btnClose: "Bağla",
          },
        },
        applyCv: {
          buttons: {
            apply: "Müraciət et",
            creatCv: "CV Yarat",
            uploadCv: "Cv Yüklə",
            close: "Bağla",
          },
          createCvInp: {
            title: "Tədbir üçün müraciət edin",
            jobName: "Müraciət etdiyiniz tədbir",
            jobNamePlace: "Tədbirin adını daxil edin.",
            Location: "Karyeraznızı davam elətdirmək istədiyiniz ölkə",
            LocationPlace: "Ölkənin adını daxil edin.",
            inp1: {
              label: "Ad, Soyad",
              placeholder: "ad, soyad daxil edin",
            },
            inp2: {
              label: "Yaş",
              placeholder: "Yaşı daxil",
            },
            inp3: {
              label: "E-mail",
              placeholder: "E-mail daxil edin",
            },
            inp4: {
              label: "Mobil nömrə",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Dil biliyi",
              placeholder: "Dil biliyini ətraflı şəkildə daxil edin",
            },
            inp6: {
              label: "Ünvan",
              placeholder: "Yaşayış ünvanınızı daxil edin",
            },
            inp7: {
              label: "Tədbir Haqqında",
              placeholder: "Tədbir haqqında ətraflı məlumat verin",
            },
            inp8: {
              label: "İş təcrübəniz",
              placeholder: "İş təcrübənizi ətraflı şəkild daxil edin",
            },
            inp9: {
              label: "Əvvəllər getdiyiniz ölkələr",
              placeholder: "Əvvəllər getdiyiniz ölkələri daxil edin",
            },
            inp10: {
              label: "Bizi haradan öyrəndiniz?",
              placeholder: "Bizi haradan öyrəndiyinizi daxil edin",
            },
            btn: "Göndər",
            btnClose: "Bağla",
          },
        },
        hourSalary: "Qiymət:",
        lookAtAll: "Bütün dekorlara bax",
        noResult: "Axtardığınız nəticə tapılmadı",
        events: "Həyata keçirdiyimiz axırıncı tədbirlər",
        contactAbout: "Əlaqə",
        LocationAbout: "Ünvan: Bakı Şəhəri, Azadlıq prospekti 88",
      },
    },
    en: {
      translation: {
        navbarItem: {
          about: "About",
          contact: "Contact",
          mainPage: "Home Page",
        },
        homeHeader: {
          h1: "Make your dream event come true with us!",
          p: "Course & Course Search. Start the course you want with us.",
        },
        inputPlaceholder: {
          name: "Search a event...",
          location: "Country, City",
          company: "Company",
          find: "Search",
        },
        apply: {
          h1: "Apply for an event",
          text: "Don't be upset if you didn't find the event you were looking for. Contact us and we will realize the desired event for you",
          btn: "Order decor",
          btn2: "View all decors",
          btn3: "More",
        },
        jobsHader: {
          h1: "You can rent these decors from us",
          p: "Contact us now to host your dream event.",
        },
        selection: {
          selec1: "Sort by date",
          optionDate1: "newest",
          optionDate2: "oldest",
          selec2: "Sort by price",
          optionSalary1: "from most to least",
          optionSalary2: "from least to most",
        },
        joinUs: {
          h1: "Join us on Telegram",
          text: "If you want to stay up to date with upcoming events, join us on Telegram and be the first to know about the latest events.",
          btn: "Join us on Telegram",
        },
        carusel: {
          h1: "Our Courses",
          p: "Job Opportunities Abroad After Completing Your Course",
        },
        about: {
          header: {
            h1: "Hello, Welcome to NewEra!",
            p: "The New Era production team professionally organizes weddings, engagements, birthdays, concert stages and all kinds of events.",
          },
          about2: {
            h1: "About Us",
            text: "NOur New Era Production team professionally organizes your event as a design team to make your NewEra-wedding, engagement, birthday and concert unique and special days unforgettable. Our main goal is to organize each event according to your specific wishes and requirements. We show our professionalism to make your event look great. Decoration, lighting, stage design and show program are just a few of the key parts of the success of your event. Our experienced team closely monitors every detail of your event and makes sure everything meets your schedule and expectations. We suggest that you trust us with your next event and we will organize these unique and special days according to your taste. Choose us for an unforgettable event!",
          },
          vacancy: {
            h1: "Our Events",
            text1: "-Gender Party ",
            text2: "-Name ceremony",
            text3: "-Birthday",
            text4: "-Graduation Party",
            text5: "-Engagement",
            text6: "-Henna Ceremony",
            text7: "-Wedding Ceremony",
            text8: "-Anniversary",
            text9: "-Corporate events",
          },
          obligation: {
            h1: "Obligations",
            text: " Employees of the mentioned courses are provided with free reception, accommodation, accommodation, work uniforms, medical services and banking services in the Republic of Poland. If accepted, the document fees and travel expenses during the documentation process are paid by the candidate. Candidates applying for employment are required to have work experience and foreign language skills (Russian or English) for the vacancy they have chosen.",
          },
          endText: {
            h1: "Let us create your next world-class event.",
          },
        },
        contact: {
          h1: "Do you have questions? Share with us",
          input: {
            inp1: {
              header: "Name, Surname",
              placeholder: "Enter first name, last name",
            },
            inp2: {
              header: "E-mail",
              placeholder: "Enter your email account",
            },
            inp3: {
              header: "Mobile number",
              placeholder: "xx xxx xx xx",
            },
            inp4: {
              header: "Message",
              placeholder: "Enter your message...",
            },
            btn: "Send",
            validation: {
              empty: "*Fill this box!",
            },
          },
        },
        alert: {
          success: "Your message has been successfully sent!",
          faild: "Something went wrong!",
        },
        alert2: {
          success: "Your request has been successfully sent!",
          faild: "Something went wrong!",
        },
        footer: {
          copyright: "Copyright © 2023 NewEra. All rights reserved.",
          pages: {
            h1: "Pages",
            about: "About Us",
            contact: "Contact",
            mainPage: "Home Page",
            watchUs: "Watch Us",
          },
          contact: {
            h1: "Contact Information",
            location1: "Head office: Lermontov Street 109",
            location2: "Azadlig branch address: Baku city, Azadlig avenue 88",
            location3:
              "Education and production plant address: Alasgarov Gayibov 1222",
          },
        },
        detail: {
          h1: "About Announcement",
          buttons: {
            apply: "Apply",
            creatCv: "Apply",
            uploadCv: "Download Ready Cv",
            close: "Close",
          },
          createCvInp: {
            title: "Create your own CV",
            jobName: "Job Name",
            companyName: "Company",
            Location: "Country where you want to continue your career",
            inp1: {
              label: "Name, Surname",
              placeholder: "enter first name, last name",
            },
            inp2: {
              label: "Age",
              placeholder: "Enter age",
            },
            inp3: {
              label: "E-mail",
              placeholder: "Enter Email",
            },
            inp4: {
              label: "Mobile number",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Language knowledge",
              placeholder: "Enter language skills in detail",
            },
            inp6: {
              label: "Your residential address",
              placeholder: "Enter your residential address",
            },
            inp7: {
              label: "Education",
              placeholder: "Enter your education level in detail",
            },
            inp8: {
              label: "Your work experience",
              placeholder: "Enter your work experience in detail",
            },
            inp9: {
              etiket: "Əvvəllər getdiyiniz ölkələr",
              placeholder: "Daha əvvəl getdiyiniz ölkələri daxil edin",
            },
            inp10: {
              etiket: "Bizim haqqımızda haradan öyrəndiniz?",
              placeholder: "Bizim haqqımızda haradan öyrənmisiniz",
            },
            btn: "Send",
            btnClose: "Close",
          },
        },
        applyCv: {
          buttons: {
            apply: "Apply the Announcement",
            creatCv: "Apply ",
            uploadCv: "Upload Cv",
            close: "Close",
          },
          createCvInp: {
            title: "Apply the Announcement",
            jobName: "The course you are applying for",
            jobNamePlace: "Enter the name of the course.",
            Location: "Country where you want to continue your career",
            LocationPlace: "Enter the name of the country.",
            inp1: {
              label: "Name, Surname",
              placeholder: "enter first name, last name",
            },
            inp2: {
              label: "Age",
              placeholder: "Enter age",
            },
            inp3: {
              label: "E-mail",
              placeholder: "Enter Email",
            },
            inp4: {
              label: "Mobile number",
              placeholder: "xx xxx xx xx",
            },
            inp5: {
              label: "Language knowledge",
              placeholder: "Enter language skills in detail",
            },
            inp6: {
              label: "Your address",
              placeholder: "Enter your residential address",
            },
            inp7: {
              label: "About Event",
              placeholder: "Provide details about the event",
            },
            inp8: {
              label: "Your work experience",
              placeholder: "Enter your work experience in detail",
            },
            inp9: {
              label: "Countries you've been to before",
              placeholder: "Enter countries you've been to before",
            },
            inp10: {
              label: "Where did you learn about us?",
              placeholder: "Enter where did you learn about us",
            },
            btn: "Send",
            btnClose: "Close",
          },
        },
        hourSalary: "Price:",
        lookAtAll: "Look at all decors",
        noResult: "The result you are looking for was not found",
        events: "Our latest events",
        contactAbout: "Contact",
        LocationAbout: "Location:Baku city, Azadlig avenue 88",
      },
    },
    fallbackLng: "az",
    debug: true,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
