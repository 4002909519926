import React, { useEffect, useState,useRef  } from 'react'
import {  useParams } from "react-router-dom";
import { HideLoading, ShowLoading } from '../../redux/loaderSlice/loaderSlice';
import { getExamById, getExamEnById } from '../../apicalls/exmas';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import HomeHeader from '../../components/HomeHeader';
import HomeFooter from '../../components/HomeFooter';
import styled from './style.module.scss'
import { MdOutlineHomeWork } from 'react-icons/md';
import { CiLocationOn } from 'react-icons/ci';
import { FaBusinessTime } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import {  Modal } from 'antd';
import Swal from 'sweetalert2';
import { useFormik   } from "formik";
import * as Yup from "yup";
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const Detail = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [showCreateCVModal, setShowCreateCVModal] = useState(false);
  const form = useRef();

  const slectorvaluHome = localStorage.getItem('i18nextLng')
const getExamData = async () => {
    try {
      dispatch(ShowLoading());
      var response; 
      if(slectorvaluHome === 'az'){
        response= await getExamById({
          examId: id,
        });
        dispatch(HideLoading());
        if (response.success) {
          setData(response.data)
        } else {
          message.error(response.message);
        }
      }
      else if (slectorvaluHome === 'en'){
        response= await getExamEnById({
          examId: id,
        });
        dispatch(HideLoading());
        if (response.success) {
          setData(response.data)
        } else {
          message.error(response.message);
        }
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(()=>{
    getExamData()
  },[])
  useEffect(() => {
    // Data dəyişdikdə, formik dəyərlərini yeniləyirik
    formik.setValues({
      name: data.name,
      compName: data.compName,
      location: data.location,
      userName: '',
      email: '',
      number:'',
      useLocation:'',
      education:''
      
    });
  }, [data]);
const apiData = data.createdAt;
const dateObject = new Date(apiData);
const year = dateObject.getFullYear();
const month = dateObject.getMonth() + 1; 
const day = dateObject.getDate();
const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} `;
const handleMüraciətEtClick = () => {
  Swal.fire({
    title: t("detail.buttons.apply"),
    // showDenyButton: true,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#3085d6',
    confirmButtonText: t("detail.buttons.apply"),
    denyButtonText: t("detail.buttons.creatCv"),
    cancelButtonText: t("detail.buttons.close"),
    confirmButtonText: t("detail.buttons.creatCv"),
    // denyButtonText: t("detail.buttons.uploadCv"),
    cancelButtonText: t("detail.buttons.close"),
  }).then((result) => {
    if (result.isConfirmed) {
      setShowCreateCVModal(true);
    } else if (result.isDenied) {
      
      const recipientEmail = "itacazerbaycan@gmail.com"; // Alıcı e-posta adresi
    const subject = `${data.name} işinə müraciət`; // E-posta konusu
    const body = `Kursun adı: ${data.name}\nŞirkət: ${data.compName}\nCvni bura yerləşdirin`; // E-posta içeriği

    // Gmail'e yönlendirmek için URL oluşturun
    const gmailUrl = `https://mail.google.com/mail/?view=cm&to=${recipientEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Gmail'e yönlendirin
    window.open(gmailUrl);
    }
  });
};

const formik = useFormik({
  initialValues: {
    name: data.name,
    compName: '',
    location: '',
    userName:'',
    email:'',
    number:'',
    useLocation:'',
    education:'',
  },
  validationSchema: Yup.object({
    name:Yup.string(),
    compName:Yup.string(),
    location:Yup.string(),
    userName: Yup.string()
      .min(5, "Bu bölmə 5 simvoldan az ola bilməz")
      .required("*Bu xananı doldurun!"),
    email:Yup.string().email('keçərsiz email adres'),
    number:Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(9, 'Minimum rəqəm sayı 9 olmalıdır')
    .max(9, 'Maximum rəqəm sayı 9 olmalıdır')
    .required('*Bu xananı doldurun!'),
    useLocation:Yup.string().min(3,'Bu bölmə 3 simvoldan az ola bilməz')
    .required('*Bu xananı doldurun!'),
    education:Yup.string().min(3,'Bu bölmə 3 simvoldan az ola bilməz')
  }),
  onSubmit: async (values) => {
    // alert(JSON.stringify(values, null, 2));
    try {
       emailjs.sendForm(
        "service_qphwtmq", // Replace with your EmailJS service ID
        "template_9capnd8", // Replace with your EmailJS template ID
        form.current,
        "sa7mjwdOsANMIUxLU" // Replace with your EmailJS user ID
      );
  
      // Successful submission
      toast(t("alert2.success"), {
        duration: 4000,
        position: 'top-center',
        icon: '✅',
        // Other toast configurations if needed
      });
      formik.resetForm();
      setTimeout( setShowCreateCVModal(false),700)
    } catch (error) {
      // Submission error
      toast(t("alert2.faild"), {
        duration: 4000,
        position: 'top-center',
        icon: '❌',
        // Other toast configurations if needed
      });
    }
  },
});

const { t } = useTranslation();
  return (
    <>
    <HomeHeader />
    
      <div className={styled.detail_body}>
        <div className={styled.container}>
        <div className={styled.job_header}>
                 {t("detail.h1")}
                 </div>
          <div className={styled.detailElement_body}>
                <div className={styled.detailElement_bodyLeft}>
               
                  <p className={styled.data_name}>{data.name}</p>
                  <p className={styled.data_compName}><MdOutlineHomeWork/>{data.compName}</p>
                  <p className={styled.data_location}><CiLocationOn/>{data.location}</p>
                  <p className={styled.data_date}><FaBusinessTime/>{formattedDate}</p>
                  <button onClick={handleMüraciətEtClick}><IoMdAdd/>{t("apply.btn")}</button>
                </div>
                <div className={styled.detailElement_bodyRight}>
                <div className={styled.image_b0dy}>
                    {data.category? <div><img src={data.category}/></div>: null}
                    {data.category2? <div><img src={data.category2}/></div>: null}
                    {data.category3? <div><img src={data.category3}/></div>: null}
                  </div>
                  <p>{data.about}</p>
                  {data.part2? <p>{data.part2}</p> : null}
                  {data.part3? <p>{data.part3}</p> : null}
                  {data.part4? <p>{data.part4}</p> : null}
                  {data.part5? <p>{data.part5}</p> : null}
                  {data.part6? <p>{data.part6}</p> : null}
                  {data.part7? <p>{data.part7}</p> : null}
                  {data.part8? <p>{data.part8}</p> : null}
                  {data.part9? <p>{data.part9}</p> : null}
                  {data.part10? <p>{data.part10}</p> : null}
                  {data.part11? <p>{data.part11}</p> : null}
                  {data.part12? <p>{data.part12}</p> : null}
                  {data.special? <p style={{color: 'red', fontWeight: '500'}}>{data.special}</p> : null}
                
                </div>
          </div>
        </div>
      </div>
      {/* CREATE CV */}
      <Modal
        title={t("detail.createCvInp.title")}
        centered
        open={showCreateCVModal}
        okText='Create'
        onOk={() => setShowCreateCVModal(false)}
         onCancel={() => setShowCreateCVModal(false)}
        footer={null}
        width={1000}
        className={styled.modal}
      >
       <form ref={form} onSubmit={formik.handleSubmit}>
            
                <div className={styled.tab_pane1}>

                  <div className={styled.inp_name_body}>
                    <p  className={styled.exam_name}>
                    {t("detail.createCvInp.jobName")}
                    </p>
                    <div className={styled.inp_body}>
                     
                      <input
                        
                        id="name"
                        name="name"
                        type="text"
                        // placeholder="İşin adını daxil edin."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={data.name}
                      />
                    </div>
                   
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("detail.createCvInp.companyName")}

                    </p>
                    <div 
                   
                    className={styled.inp_body}>
                      
                      <input
                       
                        id="compName"
                        name="compName"
                        type="text"
                        // placeholder="Şirkət logosunu link şəklində əlavə edin.Linkin doğruluğunu browserde yoxlayin!"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={data.compName}
                      />
                    </div>
                   
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                    
                    className={styled.exam_name}>
                    {t("detail.createCvInp.Location")}

                    </p>
                    <div 
                   
                    className={styled.inp_body}>
                      
                      <input
                      
                        id="location"
                        name="location"
                        type="text"
                        placeholder="Şirkət adını daxil edin"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={data.location}
                      />
                    </div>
                    
                </div>
                </div>         
                <div>
                <div className={styled.about_userBody}>

                  <div className={styled.inp_name_body}>
                    <p 
                    
                    className={styled.exam_name}>{t("detail.createCvInp.inp1.label")}</p>
                    <div 
                    
                    className={styled.inp_body}>
                      
                      <input
                        id="userName"
                        name="userName"
                        type="text"
                        placeholder={t("detail.createCvInp.inp1.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userName}
                      />
                    </div>
                    {formik.touched.userName && formik.errors.userName ? (
                      <div className={styled.require}>
                        {formik.errors.userName}
                      </div>
                    ) : null}
                  </div>
             
                {/* <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>{t("detail.createCvInp.inp2.label")}</p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="age"
                        name="age"
                        type="number"
                        placeholder={t("detail.createCvInp.inp2.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.age}
                      />
                    </div>
                    {formik.touched.age && formik.errors.age ? (
                      <div className={styled.require}>
                        {formik.errors.age}
                      </div>
                    ) : null}
                  </div> */}
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("detail.createCvInp.inp3.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t("detail.createCvInp.inp3.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className={styled.require}>
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("detail.createCvInp.inp4.label")}

                    </p>
                    <div 
                    
                    className={styled.inp_body}>
                     (+994)
                      <input
                      
                        id="number"
                        name="number"
                        type="number"
                        placeholder={t("detail.createCvInp.inp4.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.number}
                      />
                    </div>
                    {formik.touched.number && formik.errors.number ? (
                      <div className={styled.require}>
                        {formik.errors.number}
                      </div>
                    ) : null}
                  </div>
                {/* <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                     {t("detail.createCvInp.inp5.label")}

                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="langugage"
                        name="langugage"
                        type="text"
                        placeholder={t("detail.createCvInp.inp5.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.langugage}
                      />
                    </div>
                    {formik.touched.langugage && formik.errors.langugage ? (
                      <div className={styled.require}>
                        {formik.errors.langugage}
                      </div>
                    ) : null}
                  </div> */}
                <div className={styled.inp_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("detail.createCvInp.inp6.label")}
                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <input
                      
                        id="useLocation"
                        name="useLocation"
                        type="text"
                        placeholder={t("detail.createCvInp.inp6.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.useLocation}
                      />
                    </div>
                    {formik.touched.useLocation && formik.errors.useLocation ? (
                      <div className={styled.require}>
                        {formik.errors.useLocation}
                      </div>
                    ) : null}
                  </div>
                <div className={styled.textArea_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                    {t("detail.createCvInp.inp7.label")}

                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <textarea
                      
                        id="education"
                        name="education"
                        type="text"
                        placeholder={t("detail.createCvInp.inp7.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.education}
                      />
                    </div>
                    {formik.touched.education && formik.errors.education ? (
                      <div className={styled.require}>
                        {formik.errors.education}
                      </div>
                    ) : null}
                  </div>
                {/* <div className={styled.textArea_name_body}>
                    <p 
                     
                    className={styled.exam_name}>
                {t("detail.createCvInp.inp8.label")}

                    </p>
                    <div 
                  
                    className={styled.inp_body}>
                      <textarea
                      
                        id="experience"
                        name="experience"
                        type="text"
                        placeholder={t("detail.createCvInp.inp8.placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.experience}
                      />
                    </div>
                    {formik.touched.experience && formik.errors.experience ? (
                      <div className={styled.require}>
                        {formik.errors.experience}
                      </div>
                    ) : null}
                  </div> */}
                </div>

                </div>
                <div className={styled.sumbitBtnBody}>
                <button className={styled.formSumbit} type="submit">{t("detail.createCvInp.btn")}</button>
                <button onClick={() => setShowCreateCVModal(false)} className={styled.formSumbitClose} type="button">{t("detail.createCvInp.btnClose")}</button>
                </div>
          </form>
      </Modal>

    <HomeFooter/>

    </>
  )
}

export default Detail