import React, { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import HomeHeader from "../../components/HomeHeader";
import styled from "./style.module.scss";
import Carusel from "../../components/Carusel";
import HomeFooter from "../../components/HomeFooter";
import { getAllQuestion, getAllQuestionEn } from "../../apicalls/exmas";
import { message } from "antd";
import { IoMdAdd } from "react-icons/io";
import { BsTelegram } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineExpandMore } from "react-icons/md"; //pul
import { useNavigate } from "react-router-dom";
import imageSectionOne from "../.././asset/euroImage/3.jpg";
import imageSectionTwo from "../.././asset/euroImage/2.jpg";
import { useTranslation } from "react-i18next";
import ReactWhatsapp from "react-whatsapp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CaruselHeader from "../../components/CaruselHeader";
import ImageListItem from '@mui/material/ImageListItem';
import { Box, ImageList } from "@mui/material";
import Container from '@mui/material/Container';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const Home = () => {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [sortByDate, setSortByDate] = useState("newest");
  const [sortByPrice, setSortByPrice] = useState("lowToHigh");
  const [jobLocation, setJobLocation] = useState("");
  const { t } = useTranslation();

  const handleChangeLocation = (event) => {
    setJobLocation(event.target.value);
  };
  exams.filter((data) => {
    if (jobLocation === "") {
      return data;
    } else if (
      data.name.toLowerCase().includes(jobLocation.toLocaleLowerCase())
    ) {
      return data;
    }
  });
  const slectorvaluHome = localStorage.getItem("i18nextLng");

  const getExams = async () => {
    try {
      // dispatch(ShowLoading());
      var response;
      if (slectorvaluHome === "az") {
        response = await getAllQuestion();
        if (response && response.success) {
          let sorted = [...response.data];

          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
          } else {
            sorted.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
          }

          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }

          setExams(sorted);
        } else {
          message.error(response.message);
        }
      } else if (slectorvaluHome === "en") {
        response = await getAllQuestionEn();
        if (response && response.success) {
          let sorted = [...response.data];

          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
          } else {
            sorted.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
          }

          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }

          setExams(sorted);
        } else {
          message.error(response.message);
        }
      }
      else if (slectorvaluHome === "az-AZ"){
        response = await getAllQuestion();
        if (response && response.success) {
          let sorted = [...response.data];

          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
          } else {
            sorted.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
          }

          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }

          setExams(sorted);
        } else {
          message.error(response.message);
        }
      }

      // dispatch(HideLoading());
    } catch (error) {
      // dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(() => {
    getExams();
  }, [sortByDate, sortByPrice]);
  const getJobDetail = (id) => {
    navigate(`/detail/${id}`);
  };
  const [jobName, setJobName] = useState(""); // İş adı arama terimi
  const [location, setLocation] = useState(""); // Lokasyon arama terimi
  const [companyName, setCompanyName] = useState(""); // Şirket adı arama terimi
  const [visibleDataCount, setVisibleDataCount] = useState(8);
  const handleJobNameSearch = (event) => {
    setJobName(event.target.value);
  };

  const handleLocationSearch = (event) => {
    setLocation(event.target.value);
  };

  const handleCompanyNameSearch = (event) => {
    setCompanyName(event.target.value);
  };
  const handleSearchButtonClick = () => {
    // Arama işlemleri yapabilirsiniz...
    // Bu kısımda inputlardan alınan değerleri kullanarak arama işlemi gerçekleştirilebilir.

    // Input değerlerini sıfırlayalım
    setJobName("");
    setLocation("");
    setCompanyName("");
  };

  const filteredData =
    exams &&
    exams.filter((data) => {
      return (
        data.name.toLowerCase().includes(jobName.toLowerCase()) &&
        data.location.toLowerCase().includes(location.toLowerCase())
        // data? data.compName.toLowerCase().includes(companyName.toLowerCase())
      );
    });

  const displayData = jobName || location || companyName ? filteredData : exams;
  const totalDataCount = displayData.length;

  const loadMoreData = () => {
    setVisibleDataCount((prevCount) => prevCount + 8);
  };
  const jobSectionRef = useRef(null);

  const handleAraButtonClick = () => {
    if (jobSectionRef.current) {
      jobSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleSortByDateChange = (e) => {
    setSortByDate(e.target.value);
  };
  const handleSortByPriceChange = (e) => {
    setSortByPrice(e.target.value);
  };

  const itemData = [
    {
      img: 'https://www.linkpicture.com/q/1_423.jpg',
      title: 'Event',
      rows: 4,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/60aa115a4fc9445cf5484d0ff8853bec.jpeg',
      title: 'Event',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/28ce77e7c5ef1d45497babf5c23a8b92.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/25caa8ef5cdeb37cb234d13b11bc2cd9.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/386b15c2b66f188721a129ae2b7fc455.jpeg',
      title: 'Event',
      rows: 4,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/6ebea794e5afc33235803c0681a9b0db.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/6f380422fd87cc0cb32a442358745691.jpeg',
      title: 'Event',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/e76ab14bc9b450a27a638947efea04e5.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/255081a8ef8bee7c15ff6e0f5b814113.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/049164d1cb7c9b5ed1bec81da82a5c79.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/7cbec25550b19e969225d9546ca18f08.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/979605da5141cbb17e9ebfb11ef787db.jpeg',
      title: 'Event',
      rows: 4,
      cols: 1,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/5658839f99491ecc9170251abe3a5a9a.jpeg',
      title: 'Event',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://imgtr.ee/images/2023/09/19/af5cd2fc1196d27595d974b163256d55.jpeg',
      title: 'Event',
      rows: 2,
      cols: 1,
    },
  ]
  

  return (
    <div>
    <section style={{position:'relative',top:'0px'}}>
    <HomeHeader />
    <CaruselHeader/>
    </section>
      <section>
        <div className={styled.header}>
          <div className={styled.header_right}>
            <div className={styled.header_text}>
              <h2>{t("homeHeader.h1")}</h2>
              <div className={styled.searchPanel}>
                <div className={styled.search_input}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M19.7722 18.6726L14.8912 13.7917C16.1044 12.3328 16.8354 10.4593 16.8354 8.41797C16.8354 3.77628 13.0592 0 8.41774 0C3.77617 0 0 3.77628 0 8.41797C0 13.0593 3.77617 16.8353 8.41774 16.8353C10.459 16.8353 12.3325 16.1045 13.7915 14.8912L18.6725 19.7722C18.8243 19.9241 19.0234 20 19.2224 20C19.4213 20 19.6204 19.9241 19.7723 19.7722C20.0759 19.4685 20.0759 18.9763 19.7722 18.6726ZM1.55517 8.41797C1.55517 4.63381 4.63369 1.55518 8.41774 1.55518C12.2017 1.55518 15.2801 4.63381 15.2801 8.41797C15.2801 12.2018 12.2017 15.2801 8.41774 15.2801C4.63369 15.2801 1.55517 12.2018 1.55517 8.41797Z"
                      fill="#696969"
                    />
                  </svg>
                  <input
                    value={jobName}
                    onChange={handleJobNameSearch}
                    type="text"
                    placeholder={t("inputPlaceholder.name")}
                  />
                </div>

                <button onClick={handleAraButtonClick}>
                  {t("inputPlaceholder.find")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactWhatsapp
        className={styled.whatsapp}
        number="994-55-561-07-00"
        message="Salam,hər vaxtınız xeyir."
        width={"100px"}
        height={"100px"}
      >
        <IoLogoWhatsapp />
      </ReactWhatsapp>
      
      <section className={styled.gridSection}>
      <Container maxWidth="lg" sx={{paddingTop:"40px",paddingBottom:"40px"}}>
      <Box sx={{ display: 'flex', justifyContent: 'center',alignItems: 'center',flexDirection: 'column', }}>
      <p style={{textAlign:"center",fontSize:"25px",marginBottom:'10px',fontWeight:"500",color:"white"}}>{t("events")}</p>
        <p style={{width:'50px',height:'1px',backgroundColor:"white",marginBottom:'30px'}}></p>
      </Box>
      <ImageList
      sx={{ width:" 100%", height: 'auto',gap:'10px !important' }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}
        className={styled.imageGrid}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </Container>
      </section>

      <section ref={jobSectionRef} className={styled.jobs_section}>
        <div className={styled.contanier}>
          <div>
            <h2>{t("jobsHader.h1")}</h2>
            <p className={styled.job_section_headp}>{t("jobsHader.p")}</p>
            {displayData && displayData.length > 0 ? (
              <div className={styled.select_body}>
                <div className={styled.select_itemBody}>
                  <p>{t("selection.selec2")}</p>
                  <div className={styled.select}>
                    <select
                      id="salary"
                      value={sortByPrice}
                      onChange={handleSortByPriceChange}
                    >
                      <option value="lowToHigh">
                        {t("selection.optionSalary2")}
                      </option>
                      <option value="highToLow">
                        {t("selection.optionSalary1")}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className={styled.cards_body}>
            
              {displayData && displayData.length > 0 ? (
                displayData.slice(0, visibleDataCount).map((data) => {
                  return (
                    <div key={data._id} className={styled.card}>
                      <img
                        className={styled.card_jobImage}
                        src={data.category}
                        alt="job Image"
                      />
                      <div>
                        {/* <p className={styled.card_companyName}>
                          {data.compName}
                        </p> */}
                        <p className={styled.card_jobTitle}>{data.name}</p>
                      </div>
                      {/* <p className={styled.card_jobLocation}>
                        <CiLocationOn />
                        {data.location}
                      </p> */}
                      <p className={styled.card_jobLocation}>
                        {/* <RiMoneyEuroCircleLine /> */}
                        <strong>{t("hourSalary")}</strong>
                        {data.salary} ₼
                      </p>
                      <button onClick={() => getJobDetail(data._id)}>
                        <IoMdAdd />
                        <p>{t("apply.btn")}</p>
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className={styled.emptyData_body}>
                  {/* <img src={emptyData}/> */}
                  <p>{t("noResult")}</p>
                </div>
              )}
            </div>
            <div className={styled.loadMore_body}>
              {displayData.length > 0 ? (
                displayData.length > 7 ? (
                  <button onClick={loadMoreData}>
                    <MdOutlineExpandMore />
                    <p>{t("apply.btn3")}</p>
                  </button>
                ) : 
                null
              ) : (
                
                <button onClick={handleSearchButtonClick}>
                  <p>{t("apply.btn2")}</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section id="accesibility">
        <div className={styled.activity_section}>
          <div className={styled.activity_section_right}>
            <img src={imageSectionOne} />
          </div>

          <div className={styled.activity_section_left}>
            <div>
              <h2>{t("apply.h1")}</h2>
              <p>{t("apply.text")}</p>
              <button onClick={() => navigate("/apply")}>
                <IoMdAdd />
                {t("detail.buttons.creatCv")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section id="accesibility">
        <div className={styled.activity_section2}>
          <div className={styled.activity_section_right}>
            <img src={imageSectionTwo} />
          </div>

          <div className={styled.activity_section_left}>
            <div>
              <h2>{t("joinUs.h1")}</h2>
              <p>{t("joinUs.text")}</p>
              <button onClick={() => window.open("https://t.me/+7jYg7tMqsnlhMmYy")}>
                <BsTelegram />
                {t("joinUs.btn")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <HomeFooter />
      </section>
    </div>
  );
};
export default Home;
